import { configureStore } from '@reduxjs/toolkit';
import { musicboxApi } from './musicboxApi';
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
    reducer: {
        [musicboxApi.reducerPath]: musicboxApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(musicboxApi.middleware),
    devTools: true,
});

console.log(store)

setupListeners(store.dispatch);
