import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

let url = process.env.REACT_APP_API_HOST
console.log(url)

export const musicboxApi = createApi({
    reducerPath: 'subscribers',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_HOST,
    }),
    tagTypes: ['Account', 'Event', 'Merchandise', 'Subscriber'],
    endpoints: builder => ({
        token: builder.query({
            query: () => ({
                url: "/token",
                credentials: "include"
            }),
            providesTags: ['Account']
        }),
        login: builder.mutation({
            query: ({ username, password }) => {
                const body = new FormData();
                body.append('username', username)
                body.append('password', password)
                return {
                    url: '/token',
                    method: 'POST',
                    body,
                    credentials: 'include',
                }
            },
            invalidatesTags: ['Account']
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/token',
                method: 'DELETE',
                credentials: 'include',
            }),
            invalidatesTags: ['Account']
        }),
        getAllSubscribers: builder.query({
            query: () => ({
                url: '/api/subscribers',
                credentials: 'include',
            }),
            providesTags: ['Subscriber']
        }),
        getOneSubscriber: builder.query({
            query: (subscriber_id) => ({
                url: `/api/subscribers/${subscriber_id}`,
                credentials: 'include',
            }),
            providesTags: ['Subscriber']
        }),
        createSubscriber: builder.mutation({
            query: (data) => ({
                url: '/api/subscribers',
                body: data,
                method: 'POST',
                credentials: 'include',
            }),
            invalidatesTags: ['Subscriber']
        }),
    }),
});

export const { 
    useTokenQuery,
    useLoginMutation,
    useLogoutMutation,
    useGetSubscribersQuery,
    useGetAllSubscribersQuery,
    useCreateSubscriberMutation,
} = musicboxApi 

console.log(process.env.REACT_APP_API_HOST)