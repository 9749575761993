import { FaInstagram } from 'react-icons/fa';
import { BiLogoFacebookCircle } from 'react-icons/bi';


function Socials() {
    return (
        <div className="w-[250px] p-4 flex justify-start">
            <ul className="flex flex-row">
                <li className="w-[50px] h-[50px]">
                    <a className="w-[50px] h-[50px]" href="https://www.instagram.com/themusicboxofrockford/"><FaInstagram id="footer-socials" className="w-[50px] h-[50px]"/></a>
                </li>
                <li className="pl-4 w-[50px] h-[50px]">
                    <a className="w-[50px] h-[50px]" href="https://www.facebook.com/p/The-Music-Box-of-Rockford-61550873804034/"><BiLogoFacebookCircle id="footer-socials" className="w-[50px] h-[50px]"/></a>
                </li>
            </ul>
        </div>
    )
};

export default Socials;