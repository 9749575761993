import horizontalBrassTagline from "../tmb_horizontal_brass_tagline.svg";
import JotformEmbed from 'react-jotform-embed';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
import EventList from './EventList';
// import { NavLink } from "react-router-dom";

function ComingSoon() {
    return (
        <div id="landing-page-parent" className="max-h-full sm:w-[100%] sm:h-[100%] grid grid-cols-1 gap-8 pt-[68px] object-contain overflow-y-scroll">
            <div className="max-h-full content-center flex flex-col justify-center py-5">
                <img id="landing-page-logo" alt="landing-page-logo" className="mx-auto pt-4 px-4 w-full h-auto md:w-auto sm:max-w-[640px] object-contain" src={horizontalBrassTagline}></img>
                <h1 className="text-antique-beige text-center py-1">218 E State St, Rockford, IL 61104   |   (815) 986-7396</h1>
            </div>
            <div>
                <NavLink to="/vip" className="flex justify-center p-5">
                    <button id="vip-button" className="bg-antique-beige border-[3px] border-vintage-brass hover:bg-vintage-brass drop-shadow-lg text-emerald-green font-extrabold p-5">JOIN FIRST LISTENER'S CLUB</button>
                </NavLink>
            </div>
            <div id="event-list" className="p-10">
                <div id="hr-theme-slash-2" className="text-5xl text-antique-beige pb-5">
                    <div id="hr-line"></div>
                    <div id="hr-text">Upcoming Events</div>
                    <div id="hr-line"></div>
                </div>
                <div className="">
                    <EventList />
                </div>
                <div id="hr-theme-slash-2" className="text-5xl text-antique-beige pb-5">
                    <div id="hr-line"></div>
                    <div id="hr-text" className="pt-3">•••</div>
                    <div id="hr-line"></div>
                </div>
            </div>
            {/* <NavLink id="vip-page-button" type="button" to="/vip" className="bg-vintage-brass">Join Now - VIP Membership</NavLink> */}
            <div id="newsletter-parent" className="object-contain flex-grow w-full h-full">
                <div id="home-text" className="text-xl md:text-2xl lg:text-3xl mx-4 my-4 text-center uppercase">
                    Sign up for our newsletter below to get the latest news!
                </div>
                <div id="" className="min-h-[850px]">
                    <JotformEmbed id="jotform-form" src="https://form.jotform.com/233248297508161"/> 
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ComingSoon;