import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from './components/Header';
import ComingSoon from  './components/ComingSoon';
import VIPPage from './components/VIPPage';
import './App.css';

function App() {
  const domain = /https:\/\/[^/]+/;
  const basename = process.env.PUBLIC_URL.replace(domain, '')
  console.log(basename)
  
  return (
    <BrowserRouter basename={basename}>
      <Header />
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/vip" element={<VIPPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
